.modal {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  
    &.open {
      display: block;
    }
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 10px;
    margin: 10% auto;
    padding: 20px;
    height: auto;
    border: 1px solid #888;
    width: 30%;
    position: relative;
  
    .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  