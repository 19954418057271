.no-records-wrapper{
    height: 65vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-records{
    height: auto;
    color: #5d5d5d;
}