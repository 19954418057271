@import "../../../sass/colors";

.estimation-section-wrapper {
  width: calc(100vw - 320px);
  max-width: calc(100vw - 320px);
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: row;

  .estimation-section-left {
    flex: 70%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .estimation-section-right {
    flex: 30%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
td{
  input{
    border: none;
    outline: none;
    transition: all 0.3s ease-out;
  }
  input:hover{
    background-color: #d3d3d3;
    color: white;
  }
  button{
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
  }
}

.two-inputs {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.two-inputs-section {
  display: flex;
  flex: 50%;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.input-label {
  margin-bottom: 8px;
  margin-top: 2px;
  font-weight: 200;
  font-size: 13px;
  color: #54545490;
}


.login-input-half {
  width: 100%;
  height: 38px;
  border: none;
  outline: none;
  border: 1px solid  rgba(211, 211, 211, 0.8);;
  border-radius: 4px;
  font-size: 20px;
  padding-left: 18px;
  margin-bottom: 2px;
  margin-top: 2px;
  transition: all ease-out 0.3s;
}

.login-input-half:hover{
  border: 1px solid #007BFF !important;
}
.heading-custom{
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 600;
  color: #545454;
}
hr{
  border: 0;
  margin-top: 20px;
  margin-bottom: 16px;
  opacity: .0;
  border-top: 1px dotted #d3d3d3;
}
.login-input {
  width: 95%;
  height: 38px;
  border: none;
  outline: none;
  border: 1px solid #d3d3d361;
  border-radius: 4px;
  font-size: 20px;
  padding-left: 18px;
  margin-bottom: 2px;
  margin-top: 2px;
}


.box-table-container {
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    width: 100%;
    margin: 0 auto;
  }
  
  .box-table {
    width: 100%;
    border-collapse: collapse;
    input{
        width: 100%;
        height: 20px;
    }
  }
  
  .box-table th,
  .box-table td {
    border: 1px solid #e6e6e6;
    padding: 4px;
    text-align: center;
    font-size: 12px;
  }
  
  .box-table th {
    background-color: #e6e6e6;
    font-weight: bold;
  }
  
  .add-row-button {
    margin: 10px;
    padding: 10px 20px;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-row-button:hover {
    background-color: $primary-color;
  }
  .estimated-header{
    background-color:  $primary-color;
    color: white;
    margin: 0;
    padding-top: 30px;
    padding-bottom:30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .estimated-result--summary-table {
    text-align: center;
    margin: 20px;
    width: 100%;
    margin: 0 auto;
    border: 1.5px solid #d3d3d34c;
    border-radius: 10px;
    tr{
      border-bottom: 1px dashed #d3d3d3;
      padding-top: 10px;
    }
  }
  
  .estimated-result--table {
    width: 100%;
    border-collapse: collapse;
    padding-left: 24px;
    padding-right: 24px;
  }
  
  .estimated-result--table-header {
    font-weight: bold;
    background-color: #f2f2f2;
  }
  
  .estimated-result--table-footer {
    font-weight: bold;
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $primary-color;
  }
  
  .estimated-result--table-cell {
    padding: 8px;
    text-align: left;
  }
  
  .estimated-result--table-cell-value{
    font-size: 16px;
    font-weight: 700;
  }
  /* Other CSS rules with classNames prefixed */
  .city-selected{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
  }
  
  .disclaimer{
    color: rgb(25 93 211);
    margin-top: 10px;
    max-width: 360px;
    font-size: 14px;
  }

  .flex-col{
    display: flex;
    flex-direction: column;
  }