@import '../../sass/_colors.scss';

.App {
  text-align: center;
  font-family: Arial, sans-serif;

  .content-wrapper {
    background-color: #ffffff;
    min-height: calc(100vh - 56px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    .content-left-section {
      flex: 40%;
      height: calc(100vh - 56px);
      margin-top: 56px;
      background-color: #f6f8fc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #161616;
      padding: 60px;
      

      h1{
        font-size: 20px;
      }
      span{
        font-size: 14px;
        color: #9c9c9c;
        text-align: left;

        a{
          cursor: pointer;
            font-weight: 600;
            color: $primary-color;
        }
      }
      
    .input-label{
      margin-bottom: 8px;
    }
    
    .error-border{
      border-left: red 2px solid !important;
      background-color: rgba(255, 0, 0, 0.057);
      border-radius: 4px;
    }

    .two-inputs{
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }

    .two-inputs-section{
      display: flex;
      flex: 50%;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
    }

    .login-input-half{
      width: 100%;
      height: 54px;
      border: none;
      outline: none;
      border-left: $primary-color 2px solid;
      border-radius: 4px;
      font-size: 20px;
      padding-left: 18px;
      margin-bottom: 20px;
  }
    .login-input{
        width: 95%;
        height: 54px;
        border: none;
        outline: none;
        border-left: $primary-color 2px solid;
        border-radius: 4px;
        font-size: 20px;
        padding-left: 18px;
        margin-bottom: 20px;
    }

    .login-options{
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        a{
          cursor: pointer;
            color: $primary-color;
        }
    }

    button{
        width: 100%;
        height: 54px;
        border: none;
        outline: none;
        color: white;
        background-color: $primary-color;
        border-radius: 4px;
        font-size: 20px;
        padding-left: 18px;
        margin-bottom: 20px;
    }
    }
    .content-right-section {
        padding: 40px;
        flex: 60%;
        height: calc(100vh - 56px);
        background-color: #fefefe;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h1 {
      font-size: 2rem;
      margin-bottom: 20px;
    }

    .login-form {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid #ccc;
      padding: 20px;
      border-radius: 5px;
      max-width: 300px;
      margin: 0 auto;

      input[type="email"],
      input[type="password"] {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
      }

      button {
        width: 100%;
        background-color: $primary-color;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        font-size: 1rem;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    p {
      margin-top: 20px;

      a {
        color: $primary-color;
        text-decoration: none;
      }
    }

  }
}

.asterisk-span{
  color: red !important;
  font-size: 18px !important;
}