@import '../../../sass/_colors.scss';

  .button-custom {
    padding: 10px;
    width: 160px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    color: #f9f9f9;
    cursor: pointer;
    background-color: $primary-color;
    z-index: 1;
  }

  .squareOutlined{
    padding: 10px;
    width: 40px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    z-index: 1;
  }
.disabled{
  opacity: 0.2;
  cursor: not-allowed;
}
  
  