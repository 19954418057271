.section-option {
  height: 60%;
  min-height: 140px;
  width: 100%;
  margin-left: 22px;
  border: 1px solid #d3d3d350;
  background-color: white;
  border-radius: 14px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: all 0.4s ease-out;
}

.section-option:hover{
  border: 1px solid transparent;
  box-shadow: 0px 0px 15px #d3d3d379;
}

.section-option-icon {
  width: 40px;
  height: 40px;
  padding: 6px;
  border: 1px solid #d3d3d350;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  margin-bottom: 20px;
}

.section-option:last-child {
  border-right: none;
}

.section-option-head {
  font-weight: 400;
  font-size: 18px;
}

.section-main {
  display: flex;
  margin-top: 6px;
  justify-content: space-between;
}

.section-main .section-num {
  font-size: 40px;
  font-weight: 700;
}

.section-main .section-trend {
  font-size: 12px;
  color: #39c252;
  height: fit-content;
  padding: 2px 4px 2px 4px;
  margin-left: 6px;
  border-radius: 500px;
  height: 50px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.section-main .section-trend-low {
  font-size: 12px;
  color: #dc2424;
  height: fit-content;
  padding: 2px 4px 2px 4px;
  margin-left: 6px;
  border-radius: 500px;
  height: 50px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.section-sub {
  font-size: 16px;
  color: rgb(160, 163, 166);
}

.section {
  width: calc(100vw - 320px);
  max-width: calc(100vw - 320px);
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: row;
}

.section-header {
  width: calc(100vw - 320px);
  max-width: calc(100vw - 320px);
  justify-content: space-between;
  align-content: center;
  display: flex;
  z-index: 1;
  flex-direction: row;
  div {
    h4 {
      font-size: 22px;
      font-weight: 600;
    }
  }
}

.deliveries-table-header {
  width: calc(100% - 40px);
  justify-content: space-between;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  div {
    h4 {
      font-size: 18px;
      font-weight: 600;
    }
    h5{
      color: #0056b3;
      font-weight: 400;
    }
  }
}


.delivery-section {
  width: calc(100vw - 320px);
  max-width: calc(100vw - 320px);
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  height: 400px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 26px;

  .deliveries-table{
    flex: 60%;
    border: 1px solid #d3d3d350;
    border-radius: 24px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .deliveries-daily-plan{
    flex: 40%;
    border: 1px solid #d3d3d350;
    border-radius: 24px;
    margin-left: 20px;

  }
}