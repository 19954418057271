.input-with-icon {
    display: flex;
    align-items: center;
    background-color: #EAF0F7;
    border-radius: 50px;
    height: 40px;
    width: 500px;
    input {
      flex: 1;
      border: none;
      outline: none;
      padding: 18px;
      font-size: 14px;
      border-radius: 5px;
      color: #B8C3D4;
      background-color: transparent;
    }
  
    .search-icon {
      color: #B8C3D4;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  
  /* Add additional styles as needed */
  