@import "../../sass/_colors.scss";

.dashboard-component {
  display: flex;
  height: 100vh;
  z-index: 1 !important;
  overflow: hidden;
}

.ps-sidebar-container {
  z-index: 1 !important;
}

.css-dip3t8 {
  background-color: #ffffff !important;
}
.ps-sidebar-root {
  padding-top: 54px;
}

.add-button {
  background: $primary-color;
  color: #fff;
  border: none;
  padding: 10px 20px;
  display: flex;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 92%;
  justify-content: center;
  border-radius: 6px;
  font-weight: 500;

  &:hover {
    background-color: #0056b3;
  }
}

.menu-button {
  color: #474747;
  cursor: pointer;
  font-weight: 400;
  border-right: transparent 2px solid;
}

.menu-button:hover {
  font-weight: 400;
  background-color: transparent !important;
}

.active-menu-button {
  color: #0056b3;
  border-right: #0056b3 2px solid;
  background-color: #0057b314 !important;
}

.sidebar-footer {
  position: fixed;
  bottom: 0px;
  width: 250px;
}
.card {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 14px;
  z-index: 1111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.bg {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 190px;
  height: 190px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(24px);
  border-radius: 10px;
  overflow: hidden;
  outline: 2px solid white;
}

.blob {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #7367f0;
  opacity: 1;
  filter: blur(12px);
  animation: blob-bounce 5s infinite ease;
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}

.welcome-banner {
  margin-left: 2%;
  height: 200px;
  width: 96%;
  padding: 40px;
  color: white;
  border-radius: 14px;
  background: rgb(0, 97, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 97, 255, 1) 0%,
    rgba(0, 97, 255, 0.5088410364145659) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 97, 255, 1) 0%,
    rgba(0, 97, 255, 0.5088410364145659) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 97, 255, 1) 0%,
    rgba(0, 97, 255, 0.5088410364145659) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0061ff",endColorstr="#0061ff",GradientType=1);
}

.banner-heading{
font-weight: 500;
color: white;
font-size: 32px;
margin-bottom: 14px;
}