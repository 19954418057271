@import "../../sass/_colors.scss";

.header {
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(5px);
  // background-color: #FFFFFF;
  color: #161616;
  text-align: center;
  height: 56px;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0 0px 10px #d3d3d350;
  z-index: 1;
}

.header h1 {
  font-size: 1rem;
  margin: 0;
}

.nav-buttons button {
  outline: none;
  border: none;
  background-color: transparent;
  color: #161616;
  font-size: 14px;
  margin-right: 4px;
  cursor: pointer;
}

.nav-buttons .green-button {
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  background-color: $primary-color;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}
