#react-time-range{
    padding: 0px !important;
    
    .component {
        padding: 0px !important;
        
    }
    .component .label {
        padding-right: 2px !important;
    }
}