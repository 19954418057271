@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "Public Sans",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: Poppins;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  /* Customize the scrollbar width */
  width: 0px;
  height: 100px;
}

::-webkit-scrollbar-track {
  /* Customize the scrollbar track */
  background-color: transparent; /* Set the color of the scrollbar track */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  /* Customize the scrollbar thumb */
  background-color: transparent; /* Set the color of the scrollbar thumb */
  border-radius: 6px;
}

.your-specific-div::-webkit-scrollbar {
  width: 0px; /* Set the width of the scrollbar */
}

.your-specific-div::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border radius of the scrollbar thumb */
}

.your-specific-div::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}

/* For modern browsers */
* {
  scrollbar-width: thick; /* For Firefox */
  scrollbar-color: #3766e8c0 #d9d9d9; /* For Firefox */
}

*::-webkit-scrollbar {
  width: 16px; /* For vertical scrollbars */
  height: 16px; /* For horizontal scrollbars */
}

*::-webkit-scrollbar-track {
  background: #d9d9d9; /* Background of the scrollbar track */
}

*::-webkit-scrollbar-thumb {
  background-color: #3766e8c0; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 3px solid #d9d9d9; /* Creates padding around thumb */
}
