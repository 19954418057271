
  .text-field {
    padding: 10px;
    width: 140px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
  }

  .text-field-wrapper{
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
  }
  